function shouldEnableFeature() {
  return true; // always enable this
  //if ("scrollBehavior" in document.documentElement.style) {
  //  return false;
  //}
  //return true;
}

export const invokeScroll = (() => {
  let mql,
    styleSheet,
    invokedIds = {};

  const setup = (() => {
    const ready = false;
    return () => {
      if (ready) {
        return;
      }
      mql = window.matchMedia("screen and (max-width: 767px)");
      const styleElement = document.createElement("style");
      document.head.appendChild(styleElement);
      styleSheet = styleElement.sheet;
    };
  })();

  return (event) => {
    if (!shouldEnableFeature()) {
      return;
    }
    setup();
    event.preventDefault();
    const target = event.currentTarget;
    const targetId = target.hash;
    const targetElement = document.querySelector(targetId);
    const rectTop = targetElement.getBoundingClientRect().top;
    const offsetTop = window.pageYOffset;
    const buffer = mql.matches ? 10 : 70;
    const top = rectTop + offsetTop - buffer;
    const tabindex = targetElement.getAttribute("tabindex");
    if (!tabindex) {
      targetElement.setAttribute("tabindex", "-1");
      if (!invokedIds[targetId]) {
        styleSheet.insertRule(`${targetId}:focus { outline: 0 }`);
      }
    }
    targetElement.focus();
    window.scrollTo({
      top,
      behavior: "smooth",
    });
    if (!tabindex) {
      targetElement.removeAttribute("tabindex");
    }
    invokedIds[targetId] = true;
  };

})();

export const initializeScrollBehaviorPolyfill = () => {
  if (!shouldEnableFeature()) {
    return;
  }
  const anchors = document.querySelectorAll('a[href^="#"]');
  anchors.forEach((anchor) => {
    anchor.addEventListener("click", (e) => {
      e.preventDefault();
      invokeScroll(e);
    });
  });
};
