/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import * as React from "react";
import "./src/styles/global.css";
import { PreviewStoreProvider } from "gatsby-source-prismic";
import smoothscrollPolyfill from "smoothscroll-polyfill";
import { initializeScrollBehaviorPolyfill } from "./src/utils/scroll-behavior-polyfill";

export const onInitialClientRender = () => {
  // change html's className from 'js-off' to 'js-on'
  // this className will be used for noscript style handling
  const html = document.getElementsByTagName("html")[0];
  html.classList.remove("js-off");
  html.classList.add("js-on");

  smoothscrollPolyfill.polyfill();
  initializeScrollBehaviorPolyfill();
};

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
);
